// community-portal
export const host = "https://portal.purpledoorfinders.com/";

// prospect
export const prospectHost = "https://purpledoorfinders.com/";

export const paymentConfig = {
    //Converge 6 or 7-Digit Account ID *Not the 10-Digit Elavon Merchant ID*
    merchantID: "2487405",

    //Converge User ID *MUST FLAG AS HOSTED API USER IN CONVERGE UI*
    merchantUserID: "8042657794web",

    //Converge PIN (64 CHAR A/N)
    merchantPinCode: "QIU6NB1ZMKNG5XT46BQ0K5FUGWZWMDNQJANTAZX18192F67TADBAJKQ6FU39OPP5",

    // URL to Converge session token server
    //url: "https://demo.convergepay.com/hosted-payments/transaction_token",
    url: "https://www.convergepay.com/hosted-payments/transaction_token",
};

//export const checkoutJsUrl = "https://demo.convergepay.com/hosted-payments/Checkout.js";
export const checkoutJsUrl = "https://www.convergepay.com/hosted-payments/Checkout.js";

//demo
//export const elavonSdkUrl =
//    "https://uat.libs.fraud.eu.elavonaws.com/sdk-web-js/1.2.0/3ds2-web-sdk.min.js";

//prod
export const elavonSdkUrl = "https://libs.fraud.elavongateway.com/sdk-web-js/1.2.0/3ds2-web-sdk.min.js"

// demo
//export const efsUrl = "https://uat.gw.fraud.eu.elavonaws.com/3ds2";

// prod
export const efsUrl = "https://gw.fraud.elavongateway.com/3ds2";

